import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteStudent = createAsyncThunk<void, string, { rejectValue: string }>(
	"student/deleteStudent",
	async (data: string, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_DELETE_STUDENT}${data}`;
			const response = await appJSON.delete<void>(URL); 
			if (response.status === 200) {
				alert("Удачно!");
			}
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const deleteStudent = createSlice({
	name: "studentDelete",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDeleteStudent.fulfilled, (_, action) => {
			return action.payload;
		});
		builder.addCase(fetchDeleteStudent.rejected, (state) => {
			return state;
		});
	},
});

export default deleteStudent.reducer;