import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

interface Props {
	data: string | number;
    handleData: (e: SelectChangeEvent<string | number>) => void;
}

const SelectRegion: React.FC<Props> = ({data, handleData}) => {
	return (
		<Box sx={{ minWidth: 150 }}>
			<FormControl fullWidth>
				<InputLabel id="amount">المديرية</InputLabel>
				<Select labelId="amount" id="amount" value={data} label="المديرية" onChange={(e) => handleData(e)}>
					<MenuItem value={"المديرية الاقليمية النواصر"}>المديرية الاقليمية النواصر</MenuItem>
					{/* <MenuItem value={"USA"}>USA</MenuItem>
					<MenuItem value={"UK"}>UK</MenuItem>
					<MenuItem value={"Montenegro"}>Montenegro</MenuItem> */}
				</Select>
			</FormControl>
		</Box>
	);
};

export default SelectRegion;