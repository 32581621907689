import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import {appJSON} from "../../utils/AxiosConfig";

const initialState: Array<Inter.getTrimAssessment> = [
    {
        student_id: -1,
        mark: "",
        id: null,
        student_name: "",
        student_surname: "",
    }
];

export const fetchGetTrimAssessment = createAsyncThunk<Inter.getTrimAssessment[], Inter.queryTrimAssessment, { rejectValue: string }>(
	"trimAssessment/getTrimAssessment",
	async (data: Inter.queryTrimAssessment, {rejectWithValue}) => {

		try {
			const URL = `${process.env.REACT_APP_GET_TRIM_ASSESSMENT}`;
			const response = await appJSON.get<Inter.getTrimAssessment[]>(
				URL, {
					params: data
				});
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getTrimAssessment = createSlice({
	name: "get_trim_assessment",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchGetTrimAssessment.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchGetTrimAssessment.rejected, (state) => {
			return state;
		});
	},
});

export default getTrimAssessment.reducer;
