import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import app from "../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.IMARKS = {
	courses: [
		{
			title: "",
			marks: [
				{
					mark: null,
					date: "2023-00-00T23:35:40.081297",
					id: null,
					lesson_id: 0
				}
			],
			exam_mark: 0,
			exam_mark_id: 0,
			id: 0
		}
	]
};


export const fetchMarks = createAsyncThunk<Inter.IMARKS, Inter.IGetMarks, { rejectValue: string }>(
	"marks/getMarks",
	async (data: Inter.IGetMarks, { rejectWithValue }) => {
		try {
			const URL = data.role === "student" ? `${process.env.REACT_APP_GET_MARKS}` : `${process.env.REACT_APP_GET_PARENT_MARKS}`;
			const response = await app.get<Inter.IMARKS>(
				URL, {
				params: {
					trimester_id: data.trimester_id
				}
			});

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getMarksSlice = createSlice({
	name: "marks",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMarks.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchMarks.rejected, (state) => {
			return state;
		});
	},
});



export default getMarksSlice.reducer;
