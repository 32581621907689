import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

interface Props {
	data: string | number;
    handleData: (e: SelectChangeEvent<string | number>) => void;
}

const SelectSchool: React.FC<Props> = ({data, handleData}) => {
	return (
		<Box sx={{ minWidth: 150, marginTop: "50px" }}>
			<FormControl fullWidth>
				<InputLabel id="amount">المؤسسة</InputLabel>
				<Select labelId="amount" id="amount" value={data} label="المؤسسة" onChange={(e) => handleData(e)}>
					<MenuItem value={"مجموعة مدارس الزاوية"}>مجموعة مدارس الزاوية</MenuItem>
					{/* <MenuItem value={"3432"}>3432</MenuItem>
					<MenuItem value={"3432"}>3432</MenuItem>
					<MenuItem value={"2432"}>2432</MenuItem> */}
				</Select>
			</FormControl>
		</Box>
	);
};

export default SelectSchool;