import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import {AppDispatch} from "../store";
import app from "../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.getCourse[] = [
	{
		title: "",
		id: 0
	}
];

export const getCourse = createAsyncThunk<Inter.getCourse[], Inter.getQueryCourse, { rejectValue: string }>(
	"course/getCourse",
	async (data: Inter.getQueryCourse, {rejectWithValue}) => {
		try {
		
            const params = {
                ...(data.group_id_filter && { group_id_filter: data.group_id_filter })
            };
		  
			const URL = `${process.env.REACT_APP_GET_COURSE}`;
			const response = await app.get<Inter.getCourse[]>(URL, {
			    params
			});

			return response.data;
		} catch (error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getCourseSlice = createSlice({
	name: "get_course",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCourse.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(getCourse.rejected, (state) => {
			return state;
		});
	},
});


export const fetchGetCourse = (data: Inter.getQueryCourse) => {
	return async (dispatch: AppDispatch) => {
		try {
			await dispatch(getCourse(data));
			// console.log(response);
		} catch (error) {
			console.error("Произошла ошибка", error);
		}
	};
};


export default getCourseSlice.reducer;
