import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchLesson = createAsyncThunk<void, Inter.patchLesson, { rejectValue: string }>(
	"lesson/patchLesson",
	async (data: Inter.patchLesson, {rejectWithValue}) => {
		try {
			const isActive = {
				is_active: data.is_active
			};

			const URL = `${process.env.REACT_APP_PATCH_ACTIVE_LESSON}/${data.id}`;
			const response = await appJSON.patch<void>(
				URL, isActive);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const patchLesson = createSlice({
	name: "patchLesson",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPatchLesson.fulfilled, (_, action) => {
			return action.payload;
		});
		builder.addCase(fetchPatchLesson.rejected, (state) => {
			return state;
		});
	},
});


export default patchLesson.reducer;