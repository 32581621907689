import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import {appJSON} from "../../utils/AxiosConfig";

const initialState: Array<Inter.getQuarterlyGrade> = [
    {
        student_id: -1,
        trimester_id: -1,
        course_id: -1,
        mark: "", 
        description: "",
        id: -1,
        student_name: "",
        student_surname: "",
        course_title: ""
    }
];

export const fetchGetQuarterlyGrade = createAsyncThunk<Inter.getQuarterlyGrade[], Inter.queryQuarterlyGrade, { rejectValue: string }>(
	"quarterlyGrade/getQuarterlyGrade",
	async (data: Inter.queryQuarterlyGrade, {rejectWithValue}) => {

		try {
			const URL = `${process.env.REACT_APP_GET_QUARTERLY_GRADE}`;
			const response = await appJSON.get<Inter.getQuarterlyGrade[]>(
				URL, {
					params: data
				});
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getQuarterlyGrade = createSlice({ 
	name: "get_quarterly_grade",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchGetQuarterlyGrade.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchGetQuarterlyGrade.rejected, (state) => {
			return state;
		});
	},
});

export default getQuarterlyGrade.reducer;
