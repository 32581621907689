import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchMasterTeacher = createAsyncThunk<void, Inter.addMasterTeacher, { rejectValue: string }>(
	"teacher/addMasterTeacher",
	async (data: Inter.addMasterTeacher, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_ADD_MASTER_TEACHER}`;
			const response = await appJSON.patch<void>(
				URL, data);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const createMasterTeacher = createSlice({
	name: "schedule",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMasterTeacher.fulfilled, (_, action) => {
			return action.payload;
		});
		builder.addCase(fetchMasterTeacher.rejected, (state) => {
			return state;
		});
	},
});

export default createMasterTeacher.reducer;