import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import app from "../../utils/AxiosConfig";

const initialState: Inter.IHOMEWORK = {
	homeworks: [
		{
			course: {
				title: ""
			},
			date: "",
			homework: "",
			description: "",
			theme: ""
		}
	],
};

// a reducer for getting homework

export const fetchHomework = createAsyncThunk<Inter.IHOMEWORK, Inter.getHomework, { rejectValue: string }>(
	"homework/getHomework",
	async (data: Inter.getHomework, {rejectWithValue}) => {
		try {
			const URL = data.role === "student" ? `${process.env.REACT_APP_GET_HOMEWORK}` : `${process.env.REACT_APP_GET_PARENT_HOMEWORK}`;
			const response = await app.get<Inter.IHOMEWORK>(URL);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getHomeworkSlice = createSlice({
	name: "homework",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchHomework.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchHomework.rejected, (state) => {
			return state;
		});
	},
});


export default getHomeworkSlice.reducer;