import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchQuarterlyGrade = createAsyncThunk<void, Inter.patchQuarterlyGrade, { rejectValue: string }>(
    "quarterlyGrade/patchQuarterlyGrade",
    async (data: Inter.patchQuarterlyGrade, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_PATCH_QUARTERLY_GRADE}/${data.id}`;
			const response = await appJSON.patch<void>(
				URL, data);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const patchQuarterlyGrade = createSlice({
	name: "patchQuarterlyGrade",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPatchQuarterlyGrade.fulfilled, (_, action) => {
			return action.payload;
		});
		builder.addCase(fetchPatchQuarterlyGrade.rejected, (state) => {
			return state;
		});
	},
});


export default patchQuarterlyGrade.reducer;