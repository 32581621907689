import React, {ChangeEvent, useState} from "react";
import styles from "./Auth.module.scss";
import {fetchUser} from "../../redux/slices/authSlice";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../redux/store";
import * as Inter from "../../interfaces/Interfaces";
import SelectRegion from "./selects/SelectRegion";
import SelectSchool from "./selects/SelectSchool";
import Inputs from "./inputs/Inputs";
import {SelectChangeEvent} from "@mui/material/Select";
import Button from "@mui/material/Button";

const Auth : React.FC  = ()  => {
	
	const [data, setData] = useState<Inter.IData>({country: "Russia", school: "1234" , login: "", password: ""});
	
	const dispatch = useAppDispatch();
	const navigate: NavigateFunction = useNavigate();

	const handleChange = (e: ChangeEvent<Inter.InputElement>, name: string) => {
		setData((prevData) => {
			return { ...prevData, [name]: e.target.value };
		});
	};

	const handleSelect = (e: SelectChangeEvent<string | number>, title: string) => {
		setData({...data, [title]: String(e.target.value)});
	};
  
	
	const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		
		if (data.login && data.password && data.country && data.school) {

			// An object with the data to be sent to the backend

			const auth: Inter.IAuth = {
				grant_type: Inter.grandType.password,
				username: data.login,
				password: data.password,
				refresh_token: null
			};

			try {
				await dispatch(fetchUser(auth));
                if (sessionStorage.getItem("auth")) {
                    navigate("/");
                }
			} catch (error) {
				console.error("Произошла ошибка", error);
			}
		}
	};
	
	return (
		<section className={styles.section}>
			<div className={styles.title}>
				<h1>Tilmidi</h1>
			</div>
			<div className={styles.form}>
				<form className={styles.formBlock}>
					<SelectRegion data={data.country} handleData={(e) => handleSelect(e, "country")}/>
					<SelectSchool data={data.school} handleData={(e) => handleSelect(e, "school")}/>
					<Inputs dataLogin={data.login} dataPassword={data.password} handleLogin={(e:React.ChangeEvent<Inter.InputElement>) => handleChange(e, "login")} handlePassword={(e:React.ChangeEvent<HTMLInputElement>) => handleChange(e, "password")}></Inputs>
					<Button variant="contained" className={styles.verifyButton} onClick={(e) => handleSubmit(e)}>تأكيد</Button>
				</form> 
			</div>
		</section>
	);				
};

export default Auth;