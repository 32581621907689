import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import authReducer from "./slices/authSlice";
import teacherReducer from "./slices/teacherSlice";
import homeworkReducer from "./slices/getHomeworkSlice";
import scheduleReducer from "./slices/getScheduleSlice";
import marksReducer from "./slices/marksSlice";
import directorsReducer from "./slices/directorsSlice";
import directorReducer from "./slices/directorSlice";
import teacherInfoReducer from "./slices/teachersInfoSlice";
import teachersMarksReduce from "./slices/teachersMarksSlice";
import createHomework from "./slices/createHomeworkSlice";
import loadingSlice from "./slices/loadingSlice";
import createPeriodSlice from "./slices/periodsCreate";
import studentsEditReduce from "./slices/studentsEdit";
import createCourseReducer from "./slices/createCourse";
import getCourseReducer from "./slices/getCourse";
import studentsCourseReducer from "./slices/studentsCourseSlice";
import getAllGroupsReducer from "./slices/getAllGroups";
import addMasterTeacherReducer from "./slices/addMasterTeacher";
import getAllStudentsReducer from "./slices/getAllStudents";
import getAllTeachersReducer from "./slices/GetAllTeachers";
import saveStudentReducer from "./slices/saveStudent";
import saveTeacherReducer from "./slices/saveTeacher";
import createScheduleReducer from "./slices/createSchedule";
import getFirstWeekReducer from "./slices/getFirstWeek";
import getPeriodsReducer from "./slices/getPeriods";
import patchStudentReducer from "./slices/patchStudent";
import deleteStudentReducer from "./slices/deleteStudent";
import createCGTReducer from "./slices/createCGT";
import deleteScheduleReducer from "./slices/deleteSchedule";
import deleteSubjectReducer from "./slices/deleteSubject";
import createMarkReducer from  "./slices/createMark";
import createGroupReducer from "./slices/createGroup";
import deleteMarkReducer from "./slices/deleteMark";
import deleteGroupReducer from "./slices/deleteGroup";
import getCGTReducer from "./slices/getCGT";
import deleteCGTReducer from "./slices/deleteCGT";
import deleteTeacherReducer from "./slices/deleteTeacher";
import getCredentialsReducer from "./slices/getCredentials";
import patchMarksReducer from "./slices/patchMarks";
import getTeachHomewkReducer from "./slices/getTeachHomework";
import patchTeachHomewkReducer from "./slices/patchTeachHomework";
import downloadExcelStudentsSliceReducer from "./slices/downloadStudents";
import downloadExcelTeacehrsSliceReducer from "./slices/downloadTeachers";
import deletePeriodReducer from "./slices/deletePeriod";
import patchCGTReducer from "./slices/patchCGT";
import getAssessmentReducer from "./slices/getAssessment";
import createAssessmentReducer from "./slices/createAssessment";
import patchAssessmentReducer from "./slices/patchAssessment";
import deleteAssessmentReducer from "./slices/deleteAssessment";
import createQuarterlyGradeReducer from "./slices/createQuarterlyGrade";
import getQuarterlyGradeReducer from "./slices/getQuarterlyGrade";
import patchQuarterlyGradeReducer from "./slices/patchQuarterlyGrade";
import deleteQuarterlyGradeReducer from "./slices/deleteQuarterlyGrade";
import createParentReducer from "./slices/createParent";
import getParentsReducer from "./slices/getParents";
import patchParentReducer from "./slices/patchParent";
import deleteParentReducer from "./slices/deleteParent";
import createTrimAssessmentReducer from "./slices/createTrimAssessment";
import getTrimAssessmentReducer from "./slices/getTrimAssessment";
import patchTrimAssessmentReducer from "./slices/patchTrimAssessment";
import deleteTrimAssessmentReducer from "./slices/deleteTrimAssessment";
import patchLessonReducer from "./slices/patchLesson";
import patchDRScheduleReducer from "./slices/patchDRSchedule";

const rootReducer = combineReducers({
	auth: authReducer,
	teacher: teacherReducer,
	homework: homeworkReducer,
	schedule: scheduleReducer,
	marks: marksReducer,
	directors: directorsReducer,
	director: directorReducer,
	createHomework: createHomework,
	loading: loadingSlice,
	teacherInfo: teacherInfoReducer,
	teachersMarks: teachersMarksReduce,
	createPeriod: createPeriodSlice,
	studentsEDIT: studentsEditReduce,
	createCourse: createCourseReducer,
	getCourse: getCourseReducer,
	studentCourse: studentsCourseReducer,
	getAllGroups: getAllGroupsReducer,
	addMasterTeacher: addMasterTeacherReducer,
	getAllStudents: getAllStudentsReducer,
	getAllTeachers: getAllTeachersReducer,
	saveStudent: saveStudentReducer,
	patchStudent: patchStudentReducer,
	deleteStudent: deleteStudentReducer,
	saveTeacher: saveTeacherReducer,
	deleteTeacher: deleteTeacherReducer,
	createSchedule: createScheduleReducer,
	getFirstWeek: getFirstWeekReducer,
	getPeriods: getPeriodsReducer,
	createCGT: createCGTReducer,
	deleteSchedule: deleteScheduleReducer,
	deleteSubject: deleteSubjectReducer,
	createMark: createMarkReducer,
	createGroup: createGroupReducer,
	deleteMark: deleteMarkReducer,
	deleteGroup: deleteGroupReducer,
	getCGT: getCGTReducer,
	deleteCGT: deleteCGTReducer,
	getCredentials: getCredentialsReducer,
	patchMarks: patchMarksReducer,
	getTeachHomewk: getTeachHomewkReducer,
	patchTeachHomewk: patchTeachHomewkReducer,
	downloadExcelStudents: downloadExcelStudentsSliceReducer,
	downloadExcelTeachers: downloadExcelTeacehrsSliceReducer,
	deletePeriod: deletePeriodReducer,
	patchCGT: patchCGTReducer,
	getAssessment: getAssessmentReducer,
	createAssessment: createAssessmentReducer,
	patchAssessment: patchAssessmentReducer,
	deleteAssessment: deleteAssessmentReducer,
	createQuarterlyGrade: createQuarterlyGradeReducer,
	getQuarterlyGrade: getQuarterlyGradeReducer,
	patchQuarterlyGrade: patchQuarterlyGradeReducer,
	deleteQuarterlyGrade: deleteQuarterlyGradeReducer,
	createParent: createParentReducer,
	patchParent: patchParentReducer,
	getParents: getParentsReducer,
	deleteParent: deleteParentReducer,
	createTrimAssessment: createTrimAssessmentReducer,
	getTrimAssessment: getTrimAssessmentReducer,
	patchTrimAssessment: patchTrimAssessmentReducer,
	deleteTrimAssessment: deleteTrimAssessmentReducer,
	patchLesson: patchLessonReducer,
	patchDRSchedule: patchDRScheduleReducer
});

export const store = configureStore({
	reducer: rootReducer,
});

// Типы для диспетчера и состояния
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// Hook, который предоставляет типизированный доступ к диспетчеру
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export default store;
