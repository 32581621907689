import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {
	id: 0
};

export const fetchCreateGroup = createAsyncThunk<{id: number}, Inter.createGroup, { rejectValue: string }>(
	"group/createGroup",
	async (data: Inter.createGroup, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_CREATE_GROUP}`;
			const response = await appJSON.post<{id: number}>(
				URL, data);

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const createGroupSlice = createSlice({
	name: "create_group",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCreateGroup.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchCreateGroup.rejected, (state) => {
			return state;
		});
	},
});

export default createGroupSlice.reducer;