import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import app from "../../utils/AxiosConfig";

const initialState: Inter.teacherInfo = {
	name: "",
	groups: [
		{
			name: "",
			courses: [
				{
					title: "",
					id: 0 
				}
			],
			id: 0
		}
	],
	id: 0
};

// a reducer for getting homework

export const fetchTeacherInfo = createAsyncThunk<Inter.teacherInfo, void, { rejectValue: string }>(
	"teacher/teacherInfo",
	async (_, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_GET_TEACHER_INFO}`;
			const response = await app.get<Inter.teacherInfo>(URL);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getTeacherInfoSlice = createSlice({
	name: "teacherInfo",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTeacherInfo.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchTeacherInfo.rejected, (state) => {
			return state;
		});
	},
});

export default getTeacherInfoSlice.reducer;