import { createSlice } from "@reduxjs/toolkit";

export const initialStateLoading = {
	loading: false,
};

const dataSlice = createSlice({
	name: "loading",
	initialState: initialStateLoading,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setLoading } = dataSlice.actions;

export default dataSlice.reducer;