import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";

const initialState: Inter.getAllGroups[] = [
	{
		name: "",
		id: 0,
		master_teacher: {
			first_name: "",
			last_name: "",
			id: 0
		},
		grade: ""
	},
];

export const fetchAllGroups = createAsyncThunk<Inter.getAllGroups[], void, { rejectValue: string }>(
	"groups/getAllGroups",
	async (_, {rejectWithValue}) => {

		try {
			const URL = `${process.env.REACT_APP_GET_ALL_GROUPS}`;
			const response = await appJSON.get<Inter.getAllGroups[]>(
				URL);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getAllGroups = createSlice({
	name: "students_group",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAllGroups.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchAllGroups.rejected, (state) => {
			return state;
		});
	},
});

export default getAllGroups.reducer;
