import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import store from "./redux/store";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
				<App/>
			</DevSupport>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
