import * as Inter from "../../interfaces/Interfaces";
import {createSlice} from "@reduxjs/toolkit";

const initialState: Inter.editAllStudents = {
	store: {
		name: "",
		birthdate: "",
		group_name: "",
		is_male: true,
		surname: "",
		address: "",
		phone: "",
		unique_id: "",
		id: ""
	},
	editStatus: 0,
};

const editStudentSlice = createSlice({
	name: "director",
	initialState,
	reducers: {
		editStudent: (state, action) => {
			state.store = action.payload;
			state.editStatus = 1;
		},
		resetEdit: (state) => {
			state.editStatus = 0;
		}
	},
});

export const {editStudent, resetEdit} = editStudentSlice.actions;

export default editStudentSlice.reducer;