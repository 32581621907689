import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import {appJSON} from "../../utils/AxiosConfig";

const initialState: Inter.getStudents = {
	students: [
		{
			name: "",
			surname: "",
			address: "",
			is_male: true,
			unique_id: "",
			birthdate: "",
			phone: "",
			group_name: "",
			id: 0,
			group_id: 0,
		}
	],
	count: 0
};

export const fetchAllStudentsNoGroup = createAsyncThunk<Inter.getStudents, Inter.getStudentSetting, { rejectValue: string }>(
	"students/getAllStudents",
	async (data: Inter.getStudentSetting, {rejectWithValue}) => {
		try {

			const params = {
				...(data.page_number && {page_number: data.page_number}),
				...(data.elements_amount && {elements_amount: data.elements_amount}),
				...(data.name_filter && { name_filter: data.name_filter }),
				...(data.surname_filter && { surname_filter: data.surname_filter }),
				...(data.group_id && { group_id: data.group_id }),
			}; 

			const URL = `${process.env.REACT_APP_GET_ALL_STUDENTS}`;
			const response = await appJSON.get<Inter.getStudents>(
				URL, {
					params: params
				});
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getAllStudent = createSlice({
	name: "students_group",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAllStudentsNoGroup.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchAllStudentsNoGroup.rejected, (state) => {
			return state;
		});
	},
});

export default getAllStudent.reducer;