import React from "react";
import styles from "../Header.module.scss";
import {NavLink} from "react-router-dom";

interface Props  {
    onClick: () => void;
    isActive: number;
    isTeacher: boolean;
    reportLink: string;
}

const Homework: React.FC<Props> = ({ onClick, isActive, isTeacher, reportLink }) => {
	return (
		<NavLink to={reportLink} className={`${styles.navButton} ${isActive === 2 ? styles.navActive : ""}`} onClick={onClick}>
			<svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.7889 7.89164L8.75143 1.85727L8.34674 1.45258C8.25454 1.36098 8.12984 1.30957 7.99987 1.30957C7.8699 1.30957 7.7452 1.36098 7.65299 1.45258L1.21081 7.89164C1.11632 7.98576 1.04165 8.09784 0.991201 8.22129C0.940749 8.34474 0.915538 8.47704 0.917057 8.61039C0.923307 9.16039 1.38112 9.59945 1.93112 9.59945H2.59518V14.6885H13.4046V9.59945H14.0827C14.3499 9.59945 14.6014 9.49477 14.7905 9.3057C14.8836 9.21291 14.9573 9.10257 15.0075 8.98107C15.0577 8.85957 15.0832 8.72933 15.0827 8.59789C15.0827 8.33227 14.978 8.0807 14.7889 7.89164V7.89164ZM8.87487 13.5635H7.12487V10.376H8.87487V13.5635ZM12.2796 8.47445V13.5635H9.87487V10.001C9.87487 9.6557 9.59518 9.37602 9.24987 9.37602H6.74987C6.40456 9.37602 6.12487 9.6557 6.12487 10.001V13.5635H3.72018V8.47445H2.22018L8.00143 2.69789L8.36237 3.05883L13.7811 8.47445H12.2796Z"/>
			</svg>
			<h3 className={styles.navTitle}>{isTeacher ? "إستعمال الزمن" : " الواجبات المنزلية"}</h3>
		</NavLink>
	);
};

export default Homework;