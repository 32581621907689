import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import app from "../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.ISCHEDULE | Inter.ITEACHERSCHEDULE = {
	Monday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "",
		date: "", 
		homework: null,
		description: null,
		is_active: false
	}],
	Tuesday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "",
		date: "",
		homework: null,
		description: null,
		is_active: false
	}],
	Wednesday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "",
		date: "",
		homework: null,
		description: null,
		is_active: false
	}],
	Thursday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "",
		date: "",
		homework: null,
		description: null,
		is_active: false
	}],
	Friday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "",
		date: "",
		homework: null,
		description: null,
		is_active: false
	}],
	Saturday: [{
		course_title: "",
		course_id: 0,
		group_name: "",
		group_id: 0,
		lesson_id: 0,
		room: "", 
		date: "",
		homework: null,
		description: null,
		is_active: false
	}],
};

export const fetchSchedule = createAsyncThunk<Inter.ITEACHERSCHEDULE, Inter.IGetSchedule , { rejectValue: string }>(
	"schedule/getSchedule",
	async (data: Inter.IGetSchedule, {rejectWithValue}) => {
		try {

			let URL: string = "";

			const params: Record<string, string | number | undefined> = {
				start_date: data.start_date,
			}

			if (data.role === "director") {
				switch(data.type) {
					case "group":
						params.group_id_filter = data.id
						break;
					case "teacher":
						params.teacher_id_filter = data.id
						break;
					default:
						break;
				}
			}

			switch (data.role) {
				case "teacher":
					URL = `${process.env.REACT_APP_GET_TEACHER_SCHEDULE}`;
					break;
				case "parent":
					URL = `${process.env.REACT_APP_GET_PARENTS_SCHEDULE}`;
					break;
				case "director":
					URL = `${process.env.REACT_APP_DIRECTOR_SCHEDULE}`
					break;
				default:
					URL = `${process.env.REACT_APP_GET_SCHEDULE}`
			}

			const response = await app.get<Inter.ITEACHERSCHEDULE>(
				URL, {
					params: params
				});


			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getScheduleSlice = createSlice({
	name: "schedule",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSchedule.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchSchedule.rejected, (state) => {
			return state;
		});
	},
});


export default getScheduleSlice.reducer;