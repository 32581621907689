import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchCreatePeriod = createAsyncThunk<void, Inter.createPeriod , { rejectValue: string }>(
	"period/createPeriod",
	async (data: Inter.createPeriod, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_CREATE_PERIOD}`;
			const response = await appJSON.post<void>(
				URL, data);

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const createPeriodSlice = createSlice({
	name: "create_period",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCreatePeriod.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchCreatePeriod.rejected, (state) => {
			return state;
		});
	},
});


export default createPeriodSlice.reducer;