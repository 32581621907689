import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import {appJSON} from "../../utils/AxiosConfig";

const initialState: Inter.ITEACHERSCHEDULE = {
	Monday: [],
	Tuesday: [],
	Wednesday: [],
	Thursday: [],
	Friday: [],
	Saturday: [],
};

export const fetchFirstWeek = createAsyncThunk<Inter.ITEACHERSCHEDULE, Inter.groupData, { rejectValue: string }>(
	"schedule/getFirstWeek",
	async (data: Inter.groupData, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_GET_FIRST_WEEK}`;
			const response = await appJSON.get<Inter.ITEACHERSCHEDULE>(
				URL, {
					params: {
						trimester_id: data.trimester,
						group_id: data.group
					}
				});
			return response.data;

		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getFirstWeek = createSlice({
	name: "firstWeek",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchFirstWeek.fulfilled, (state, action) => {
			return state = action.payload;
		});
		builder.addCase(fetchFirstWeek.rejected, (state) => {
			return state;
		});
	},
});

export default getFirstWeek.reducer;