import React from "react";
import styles from "../Header.module.scss";
import {NavLink} from "react-router-dom";

interface Props  {
    onClick: () => void;
    isActive: number;
}

const Timetable: React.FC<Props> = ({ onClick, isActive }) => {
	return (
		<NavLink to="." className={`${styles.navButton} ${isActive === 0 ? styles.navActive : ""}`} onClick={onClick} end>
			<svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13.75 2.875H11.125V1.875C11.125 1.80625 11.0688 1.75 11 1.75H10.125C10.0562 1.75 10 1.80625 10 1.875V2.875H6V1.875C6 1.80625 5.94375 1.75 5.875 1.75H5C4.93125 1.75 4.875 1.80625 4.875 1.875V2.875H2.25C1.97344 2.875 1.75 3.09844 1.75 3.375V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25H13.75C14.0266 14.25 14.25 14.0266 14.25 13.75V3.375C14.25 3.09844 14.0266 2.875 13.75 2.875ZM13.125 13.125H2.875V7.1875H13.125V13.125ZM2.875 6.125V4H4.875V4.75C4.875 4.81875 4.93125 4.875 5 4.875H5.875C5.94375 4.875 6 4.81875 6 4.75V4H10V4.75C10 4.81875 10.0562 4.875 10.125 4.875H11C11.0688 4.875 11.125 4.81875 11.125 4.75V4H13.125V6.125H2.875Z"/>
			</svg>
			<h3 className={styles.navTitle}> جدول الحصص</h3> 
		</NavLink>
	);
};

export default Timetable;