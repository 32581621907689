import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import axios from "axios";
import {appJSON} from "../../utils/AxiosConfig";

const initialState = {};

export const fetchSaveTeacher = createAsyncThunk<void, Inter.saveTeacher, { rejectValue: string }>(
	"teachers/saveTeacher",
	async (data, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_SAVE_TEACHER}`;
			const response = await appJSON.post<void>(
				URL, data);
			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const saveTeachers = createSlice({
	name: "teachers_save",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSaveTeacher.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchSaveTeacher.rejected, (state) => {
			return state;
		});
	},
});


export default saveTeachers.reducer;