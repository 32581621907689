import React from "react";
import * as Inter from "../../../interfaces/Interfaces";
import styles from "./Header.module.scss";
import Info from "./info/Info";
import Timetable from "./info/Timetable";
import Marks from "./info/Marks";
import Homework from "./info/Homework";
import TemporaryDrawer from "./toggle-menu/TemporaryDrawer";
import QuarterlyGrade from "./info/QuarterlyGrade";
import TrimAssessment from "./info/TrimAssessment";

const Header: React.FC<Inter.HeaderProps> = ({isActive , handleActive}) => {

    const role = localStorage.getItem("role");

	return (
		<header className={styles.header}>
			<Info/>
			{role === "director" ? <div className={styles.navigation}>
				<TemporaryDrawer/>
			</div> : <div className={styles.navigation}>
				<Timetable isActive={isActive} onClick={() => handleActive(0)}/>
				<Marks isActive={isActive} onClick={() => handleActive(1)} isTeacher={role === "teacher"} isDirector={role === "director"}/>
				<Homework isActive={isActive} onClick={() => handleActive(2)} isTeacher={role === "teacher"} reportLink={role === "teacher" ? "/create-timetable" : "/homework"}/>
				{role === "teacher" && (
					<>
						<QuarterlyGrade onClick={() => handleActive(3)} isActive={isActive}/>
						<TrimAssessment onClick={() => handleActive(4)} isActive={isActive}/>
					</>
				)}
			</div>}
		</header>
	);
};

export default Header;