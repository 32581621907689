import React, {lazy} from "react";
import "./App.scss";
import Auth from "./components/auth/Auth";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
const Main = lazy(() => import("./components/main/Main"));
const Marks = lazy(() => import("./components/marks/Marks"));
const Homework = lazy(() => import("./components/homework/Homework"));
const Exams = lazy(() => import("./components/assessment/Assessment"));
const Report = lazy(() => import("./components/report/Report"));
const SingleReport = lazy(() => import("./components/singleReport/SingleReport"));
const Classes = lazy(() => import("./components/classes/Classes"));
const Class = lazy(() => import("./components/class/Class"));
const Students = lazy(() => import("./components/students/Students"));
const Periods = lazy(() => import("./components/periods/Periods"));
const Teachers = lazy(() => import("./components/teachers/Teachers"));
const Parents = lazy(() => import("./components/parents/Parents"));
const DRtimetable = lazy(() => import("./components/DRtimetable/DRtimetable"));
const Courses = lazy(() => import("./components/courses/Courses"));
const CGT = lazy(() => import("./components/create-group-teacher/CGT"));
const Credentials = lazy(() => import("./components/credentials/Credentials"));
const Assessment = lazy(() => import("./components/assessment/Assessment"));
const QuarterlyGrade = lazy(() => import("./components/quarterly-grade/QuartertlyGrade"));
const TrimesterAssessment = lazy(() => import("./components/trimester-assessments/TrimesterAssessments"));

function App() : JSX.Element {

	return (
		<BrowserRouter>
			<div className="App">
				<Routes>
					<Route path="/" element={<MainLayout/>}>
						<Route index element={<Main/>}/>
						<Route path="create-timetable" element={<DRtimetable/>}/>
						<Route path="marks" element={<Marks/>}/>
						<Route path="homework" element={<Homework/>}/>
						<Route path="exams" element={<Exams/>}/>
						<Route path="report" element={<Report/>}/>
						<Route path="report/:slug" element={<SingleReport/>}></Route>
						<Route path="classes" element={<Classes/>}></Route>
						<Route path="classes/:slug" element={<Class/>}></Route>
						<Route path="students" element={<Students/>}></Route>
						<Route path="teachers" element={<Teachers/>}></Route>
						<Route path="parents" element={<Parents/>}></Route>
						<Route path="periods" element={<Periods/>}></Route>
						<Route path="courses" element={<Courses/>}/>
						<Route path="CGT" element={<CGT/>}/>
						<Route path="credentials" element={<Credentials/>}></Route>
						<Route path="assessments" element={<Assessment/>}></Route>
						<Route path="quarterly-grade" element={<QuarterlyGrade/>}></Route>
						<Route path="trimester-assessment" element={<TrimesterAssessment/>}></Route>
						<Route path="schedule" element={<DRtimetable/>}></Route>
					</Route>
					<Route path="/auth" element={<Auth/>}/>
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
