import React from "react";

//Types

export type InputElement = HTMLSelectElement | HTMLInputElement

export enum grandType {
	password = "password",
	refresh = "refresh_token"
}

// Interfaces
export interface IData {
	[key: string]: string;
}

export interface IGetSchedule {
	start_date: string;
	role: string;
	type?: string,
	id?: number
}

export interface ICreateHomework {
	group_name: string,
	lesson_date: string,
	course_title: string,
	homework: string,
	theme: string,
	description: string
}

export interface IGetMarks {
	trimester_id: number;
	role: string;
}

export interface IGetTeachersMarks {
	group_id: number,
	course_id: number,
	trimester_id: number
}

export interface ITeacher {
	[key: string]: boolean;
}

export interface IAuth {
	grant_type: grandType,
	username: string | null,
	password: string | null,
	refresh_token: string | null,
}

export interface HeaderProps {
	isActive: number;
	handleActive: (isActive: number) => void;
}

export interface IProps {
	data: string | number;
	handleData: (e: React.ChangeEvent<InputElement>) => void;
}

interface IHomeworkData {
	course: {
		title: string;
	},
	date: string,
	homework: string,
	description: string,
	theme: string
}

export interface getHomework {
	role: string;
}

export interface IHOMEWORK {
	homeworks: IHomeworkData[];
}

export interface IScheduleData {
	date: string;
	room: string | null;
	course_title: string;
    course_id: number;
    homework: string | null;
    description: string | null;
}

export interface ITeacherSchedule extends IScheduleData {
	group_name: string
	group_id: number,
	lesson_id: number,
	is_active: boolean
}

export interface ISCHEDULE {
	lessons: IScheduleData[];
}

export interface ITEACHERSCHEDULE {
	Monday: Array<ITeacherSchedule | null>,
	Tuesday: Array<ITeacherSchedule | null>,
	Wednesday: Array<ITeacherSchedule | null>,
	Thursday: Array<ITeacherSchedule | null>,
	Friday: Array<ITeacherSchedule | null>,
	Saturday: Array<ITeacherSchedule | null>
}

export interface marks {
	mark: number | null,
	date: string,
    id: number | null,
	lesson_id: number
}

export interface IMarksData {
	title: string;
	marks: marks[];
	exam_mark: number;
	exam_mark_id: number,
	id: number
}

export interface IMARKS {
	courses: IMarksData[];
}

export interface Mark {
	mark: string,
	markFN: () => void,
}

export interface ITeackersMarks {
	name: string;
	marks: marks[];
	exam_mark: number;
	exam_mark_id: number;
	id: number;
}

export interface ITEACHERSMARKS {
	students: ITeackersMarks[];
}

export interface IGetUser {
	name: string,
	surname: string,
	username: string,
	role: string
}

export interface teacherCourses {
	title: string
	id: number
}

export interface teacherGroups {
	name: string,
	courses: teacherCourses[],
	id: number
}

export interface teacherInfo {
	name: string,
	groups: teacherGroups[],
	id: number
}
export interface createPeriod {
	date_start: string,
	date_finish: string,
	semester: number
}

export interface studentsEdit {
	name: string,
	dateOfBirth: string,
	gender: string,
	adress: string,
	number: string,
	class: string
}

export interface createGroup {
	name: string,
	grade: string
}

export interface createCourse {
	title: string;
}

export interface getCourse extends createCourse {
    id: number;
}

export interface getQueryCourse {
    group_id_filter?: number;
}

export interface sendStudentsGroup {
	group: string;
}

export interface getStudentsGroup {
	name: string,
	unique_id: string,
	surname: string,
	birthdate: string,
	is_male: boolean,
	phone: string,
	address: string
}

export interface getAllGroups {
	name: string,
	id: number
	master_teacher: {
		first_name: string,
		last_name: string,
		id: number
	}
	grade: string
}

export interface addMasterTeacher {
	group_name: string | null,
	teacher_first_name: string,
	teacher_last_name: string
}

export interface getAllStudent {
	name: string,
	unique_id: string,
	surname: string,
	birthdate: string,
	is_male: boolean,
	phone: string,
	address: string,
	group_name: string,
}

export interface saveStudent {
	name: string,
	unique_id: string,
	surname: string,
	birthdate: string,
	is_male: boolean,
	phone: string,
	group_id: number,
	address: string,
	username: string,
	password: string,
	role: string,
}

export interface patchStudent extends saveStudent {
	id: number;
}

export interface getAllStudents extends getAllStudent {
	id: number,
	group_id: number
}

export interface getStudents {
	students: getAllStudents[],
	count: number;
}

export interface getAllTeachers {
	first_name: string,
	last_name: string,
	phone_number: string,
	address: string,
	is_male: boolean,
	birthdate: string,
}

export interface getAllTeachersWithID extends getAllTeachers {
	id: number,
	unique_id: string,
	master_group_id: number
}

export interface getAllTeachersWithCount {
	teachers: Array<getAllTeachersWithID>,
	count: number
}

export interface saveTeacher extends getAllTeachers {
	username: string,
	password: string,
	role: string,
	unique_id: string
}

export interface patchTeacher extends saveTeacher {
	id: number
}

export interface patchMasterTeacher {
    id: number,
    master_group_id: number | null
}

export interface editAllStudents {
	store: {
		name: string,
		unique_id: string,
		surname: string,
		birthdate: string,
		is_male: boolean,
		phone: string,
		address: string,
		group_name: string
		id: string
	},
	editStatus: number
}

export interface createSchedule {
	trimester_id: number,
	weekday: string,
	course_id: number,
	lesson_start_time: string,
	group_id: number,
	room: string
}

export interface patchSchedule {
    course_id: number,
    group_id: number,
    lesson_start_time: string,
    room: string,
    id: number
}

export interface getPeriods {
	date_start: string,
	date_finish: string,
	semester: number,
	id: number
}

export interface groupData {
	group: number,
	trimester: string | number
}

export interface CGT {
	course_title: string,
	group_name: string,
	teacher_name: string,
	trimester_id: number,
	cgt_id?: number
}

export interface createCGT {
    course_id: number,
    group_id: number, 
    teacher_id: number,
    trimester_id: number
}

export interface patchCGT extends createCGT {
    cgt_id: number
}

export interface getCGT {
	cgt_list: Array<CGT>,
	count: number
}

export interface deleteSchedule {
	id: number
}

export interface createMark {
	mark: string,
	student_id: number,
	lesson_id: number
}

export interface marksDetails {
	group: string,
	subject: string,
	data: string,
	student: string
}

export interface sendCGT {
	pageCount: string
	elements_amount: number
}

export interface getCred {
	page_number: number
}


interface studentCred {
	name: string,
	surname: string,
	username: string,
	password: string,
	group_id: number,
	group_name: string,
	id: number
}

export interface sendCredentials {
    role: string,
    page_number: number,
    elements_amount: number,
    name_filter: string | null,
    surname_filter: string | null,
    group_id: number | null
}

export interface ICredentials {
	credentials: {
		users: Array<studentCred>,
	},
	count: number
}

export interface getStudentSetting {
	page_number?: number,
	elements_amount?: string,
	name_filter?: string,
	surname_filter?: string,
	group_id?: number
}

export interface getParentsQuery extends getStudentSetting {
	phone_number: string
}

export interface patchMarks {
	id: number,
	mark: string
}

export interface getTeachersSettings {
	page_number?: number,
	elements_amount?: string,
	name_filter?: string,
	surname_filter?: string,
}

export interface patchTeachHomework {
	homework: string | null,
	theme: string | null,
	description: string | null,
	id: number
}

export interface getTeachHomework extends patchTeachHomework {
	room: string | null,
	date: string,
}

export interface createAssessment {
    title: string,
    course_id: number
}

export interface queryAssessment {
	course_id_filter?: number | null,
}

export interface getAssessment extends createAssessment {
    course_name: string,
    id: number;
}

export interface patchAssessment extends createAssessment {
    id: number;
}

export interface createQuarterlyGrade {
    student_id: number,
    trimester_id: number,
    course_id: number,
    mark: string,
    description: string
}

export interface getQuarterlyGrade {
    student_id: number,
    trimester_id: number,
    course_id: number,
    mark: string,
    description: string,
    student_name: string,
    student_surname: string,
    course_title: string,
    id: number;
}

export interface queryQuarterlyGrade {
	course_id_filter: number,
	group_id_filter: number,
	trimester_id_filter: number,
}

export interface patchQuarterlyGrade extends createQuarterlyGrade {
    id: number;
}

export interface createParent {
	name: string,
	surname: string,
	phone_number: string,
	student_id: number,
	username: string,
	password: string,
	role: string
}

export interface patchParent extends createParent {
	id: number
}

export interface getParents {
	name: string,
	surname: string,
	phone_number: string,
	student_id: number,
	student_name: string,
	student_surname: string,
	group_id: number,
	group_name: string,
	id: number
}

export interface getParentsPagination {
	parents: Array<getParents>,
	count: number
}

export interface createTrimAssessment {
	student_id: number,
	trimester_id: number,
	assessment_type_id: number,
	mark: string
}

export interface patchTrimAssessment extends createTrimAssessment {
	id: number
}

export interface getTrimAssessment {
	student_name: string,
	student_surname: string,
	student_id: number,
	mark: string | null,
	id: number | null
}

export interface queryTrimAssessment {
	trimester_id_filter: number,
	assessment_type_id_filter: number,
	group_id_filter: number,
}

export interface patchLesson {
	id: number,
	is_active: boolean
}
