import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Inter from "../../interfaces/Interfaces";
import app from "../../utils/AxiosConfig";

const initialState: Inter.ITEACHERSMARKS = { 
	students: [
		{
			name: "",
			marks: [
				{
					mark: null,
					date: "2023-09-02T23:35:40.081297", 
					id: null,
					lesson_id: 0
				}
			],
			exam_mark: 0,
			exam_mark_id: 0,
			id: 0
		}
	]
};

export const fetchTeachersMarks = createAsyncThunk<Inter.ITEACHERSMARKS, Inter.IGetTeachersMarks, { rejectValue: string }>(
	"teachers/getTeachersMarks",
	async (data: Inter.IGetTeachersMarks, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_GET_TEACHER_MARKS}`;
			const response = await app.get<Inter.ITEACHERSMARKS>(
				URL, {
					params: {
						group_id: data.group_id,
						course_id: data.course_id,
						trimester_id: data.trimester_id
					}
				});

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const getTeachersMarksSlice = createSlice({
	name: "marks",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTeachersMarks.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchTeachersMarks.rejected, (state) => {
			return state;
		});
	},
});

export default getTeachersMarksSlice.reducer;
