import React, {useEffect, useState} from "react";
import Header from "./header/Header";
import {NavigateFunction, Outlet, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Auth from "../auth/Auth";
import { isDirector } from "../../redux/slices/directorSlice";
import { Suspense } from "react";
import ReactLoading from "react-loading";

const MainLayout: React.FC = () => {


	const role = localStorage.getItem("role");
	const page = localStorage.getItem("isActive");
	const [isActive, setIsActive] = useState<number>(
		!page ? (role === "director" ? 2 : 0) : Number(page)
	);

	const dispatch = useAppDispatch();

	const handleActive = (n: number): void => {
		localStorage.setItem("isActive", `${n}`);
		setIsActive(n);
	};

	const navigate: NavigateFunction = useNavigate();

	// A function that can give us access for the timetable

	const auth = useAppSelector((state) => state.auth);

	if (role === "director") {
		dispatch(isDirector(true));
	}
	const isAuth = auth.isAuthenticated === "true";

	const refresh = sessionStorage.getItem("auth");
	useEffect(() => {
		if (!refresh || refresh === "false") {
			return navigate("/auth");
		}
	}, [auth]);

	return (
		<>
			<Header isActive={isActive} handleActive={handleActive}/>
			<Suspense fallback={<div style={{margin: "0 auto", width: "100%", height: "100vh", backgroundColor: "white"}}>
				<div style={{margin: "0 auto", width: "64px"}}>
					<ReactLoading type="spin" color="#000000"/>
				</div>
			</div>}><Outlet/></Suspense>
		</>
	);
};

export default MainLayout;
