import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import ClassIcon from '@mui/icons-material/Class';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import SubjectIcon from '@mui/icons-material/Subject';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';
import GradeIcon from '@mui/icons-material/Grade';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { arabicTitles } from './Utils';
import {Typography} from "@mui/material";

export default function TemporaryDrawer() {
    const [title, setTitle] = React.useState(" جدول الحصص");
    const [open, setOpen] = React.useState(false);
    
    const handleTitle = (string: string) => {
        setTitle(string);
        localStorage.setItem("DirectorDrawer", string);
    }

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    
    React.useEffect(() => {
        const data = localStorage.getItem("DirectorDrawer");
        setTitle(data ?? " جدول الحصص");
    }, [])

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <NavLink to="classes" onClick={() => handleTitle(arabicTitles["classes"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ClassIcon />
                            </ListItemIcon>
                            <ListItemText primary={"الاقسام"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="teachers" onClick={() => handleTitle(arabicTitles["teachers"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={"الاساتذة"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="students" onClick={() => handleTitle(arabicTitles["students"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SchoolIcon />
                            </ListItemIcon>
                            <ListItemText primary={"التلاميذ"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="parents" onClick={() => handleTitle(arabicTitles["parents"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <EscalatorWarningIcon />
                            </ListItemIcon>
                            <ListItemText primary={"أولياء الأمور"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="courses" onClick={() => handleTitle(arabicTitles["courses"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SubjectIcon />
                            </ListItemIcon>
                            <ListItemText primary={"المواد"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="periods" onClick={() => handleTitle(arabicTitles["periods"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <CalendarMonthIcon />
                            </ListItemIcon>
                            <ListItemText primary={"التوزيع السنوي"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="CGT" onClick={() => handleTitle(arabicTitles["CGT"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <LinearScaleIcon />
                            </ListItemIcon>
                            <ListItemText primary={"اسناد المواد"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="schedule" onClick={() => handleTitle(arabicTitles["schedule"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <CalendarViewMonthIcon />
                            </ListItemIcon>
                            <ListItemText primary={"استعمال الزمان"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="assessments" onClick={() => handleTitle(arabicTitles["assessments"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <ListItemText primary={"وحدات المواد"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="quarterly-grade" onClick={() => handleTitle(arabicTitles["quarterly-grade"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <GradeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"الدورات"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="trimester-assessment" onClick={() => handleTitle(arabicTitles["trimester-assessment"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={"الفروض"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="/" onClick={() => handleTitle(arabicTitles["/"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={" جدول الحصص"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
                <NavLink to="credentials" onClick={() => handleTitle(arabicTitles["credentials"])} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText primary={"تسجيل الدخول"} />
                        </ListItemButton>
                    </ListItem>
                </NavLink>
            </List>
            <Divider />
        </Box>
    );

    return (
        <div style={{marginTop: "10px"}}>
            <Button onClick={toggleDrawer(true)}>
                <Typography variant="h5">
                    {title}
                </Typography>
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}
