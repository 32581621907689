import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {appJSON} from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchTeacherExcelDownload = createAsyncThunk<BlobPart, void, { rejectValue: string }>(
	"teachers/downloadExcel",
	async (_, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_DOWNLOAD_EXCEL_TEACHERS}`;

			const response = await appJSON.get<BlobPart>(URL, {
				responseType: "blob"
			});

			const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "teachers.xlsx";

			a.addEventListener("click", () => {
				setTimeout(() => {
					window.URL.revokeObjectURL(url);
					a.remove();
				}, 1000);
			});

			a.click();

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);


const downloadExcelTeachersSlice = createSlice({
	name: "download_excel_teachers",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTeacherExcelDownload.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchTeacherExcelDownload.rejected, (state) => {
			return state;
		});
	},
});

export default downloadExcelTeachersSlice.reducer;
