import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";
import { appJSON } from "../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchCreateParent = createAsyncThunk<void, Inter.createParent, { rejectValue: string }>(
	"parent/createParent",
	async (data: Inter.createParent, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_CREATE_PARENT}`;
			const response = await appJSON.post<void>(
				URL, data); 

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);

const createParent = createSlice({
	name: "create_parent",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCreateParent.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchCreateParent.rejected, (state) => {
			return state;
		});
	},
});

export default createParent.reducer;