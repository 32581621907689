import {createSlice} from "@reduxjs/toolkit";
import * as Inter from "../../interfaces/Interfaces";

type AppState = {
    database: Inter.getAllStudents[];
    filteredData: Inter.getAllStudents[];
	initData: Inter.getAllStudents[];
}

const initialState: AppState = {
	database: [],
	filteredData: [],
	initData: [],
};

const directorSlice = createSlice({
	name: "directors",
	initialState,
	reducers: {
		initDataBase: (state, action) => {
			state.database = action.payload;
			state.initData = state.database;
		},
		filterSurname: (state, action) => {
			state.filteredData = state.database.filter(item => (item.surname.includes(action.payload) || item.name.includes(action.payload)));
			state.database = state.filteredData;
		},
		filterGender: (state, action) => {
			state.filteredData = state.database.filter(item => item.is_male === action.payload);
			state.database = state.filteredData;
		},
		filterDate: (state, action) => {
			state.filteredData = state.database.filter(item => item.birthdate.includes(action.payload));
			state.database = state.filteredData;
		},
		filterAdress: (state, action) => {
			state.filteredData = state.database.filter(item => item.address.includes(action.payload));
			state.database = state.filteredData;
			console.log(action.payload);
		},
		filterNumber: (state, action) => {
			state.filteredData = state.database.filter(item => item.phone.includes(action.payload));
			state.database = state.filteredData;
		},
		filterClass: (state, action) => {
			state.filteredData = state.database.filter(item => String(item.group_name).includes(action.payload));
			state.database = state.filteredData;
		},
		filterInitDate: (state) => {
			state.filteredData = state.initData; 
			state.database = state.filteredData;
		},
		clearAll: (state) => {
			state.database = [];
			state.filteredData = [];
			state.initData = [];
		}
	}
});

export const {initDataBase, filterSurname, filterDate, filterGender, filterAdress, filterClass, filterNumber, filterInitDate, clearAll} = directorSlice.actions;
export default directorSlice.reducer;